import getConfig from 'next/config';
import { Occupation, SelectList } from '@src/models';

export const fetchOccupations = async (
  inputValue: string
): Promise<SelectList[]> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/occupations/${inputValue}`
  );
  const json = (await result.json()) as Occupation[];
  const mapping = json.map(item => ({
    label: item.description,
    value: item.occupationId,
  }));
  return mapping;
};

export const fetchAllOccupations = async (): Promise<SelectList[]> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(`${publicRuntimeConfig.host}/api/occupations/`);
  const json = (await result.json()) as Occupation[];
  const mapping = json.map(item => ({
    label: item.description,
    value: item.occupationId,
  }));
  return mapping;
};
