import getConfig from 'next/config';

export const createQuote = async (
  referenceNumber: string,
  title: string,
  firstName: string,
  surname: string,
  gender: string,
  description: string,
  employmentType: string,
  benefitCodeBop: boolean,
  benefitCodeLifeIncome: boolean,
  benefitCodeTermLifeCover: boolean,
  benefitCodeWholeLifeCover: boolean,
  occupation: string,
  premium: string,
  adviserName: string,
  dateOfBirth: string,
  isSmoker: boolean,
  showManualDutiesQuestionsOnApp: boolean
): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      title,
      firstName,
      surname,
      gender,
      description,
      employmentType,
      benefitCodeBop,
      benefitCodeLifeIncome,
      benefitCodeTermLifeCover,
      benefitCodeWholeLifeCover,
      occupation,
      referenceNumber,
      premium,
      adviserName,
      dateOfBirth,
      isSmoker,
      showManualDutiesQuestionsOnApp,
    }),
  };

  const res = await fetch(`${publicRuntimeConfig.host}/api/setup`, requestOptions);
  return res;
};
