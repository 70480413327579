import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import styled, { createGlobalStyle } from 'styled-components';
import {
  PrimaryButton,
  TextInput,
  Select,
  Checkbox,
  SegmentedControl,
  Text,
  List,
  Indent,
  Heading,
} from '@fmi/design-system';
import { FormSection, Stack, Center, Header } from '@src/components';
import { fetchAllOccupations } from '@src/utils/fecthOccupations';
import useSWR from 'swr';
import { useFormik, FormikErrors } from 'formik';
import { displayValidationRequired } from '@src/validators';
import { removeUndefined } from '@src/utils';
import { createQuote } from '@src/utils/createQuote';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

const Index = () => {
  const GlobalStyle = createGlobalStyle`
  body {
    background: white;
    font-family: 'Roboto', sans-serif;
  }
`;
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const [loading, setLoading] = useState(false);
  const { data: OccupationOptions, error } = useSWR(
    '/api/occupations/',
    fetchAllOccupations,
    {
      revalidateOnFocus: false,
    }
  );
  if (error) console.log('Error fetching occupation from reference API');

  // useEffect(() => {}, []);

  const validateForm = (values: any) => {
    const errors: FormikErrors<any> = {
      title: displayValidationRequired(values.title, 'Title'),
      firstName: displayValidationRequired(values.firstName, 'First name'),
      surname: displayValidationRequired(values.surname, 'Surname'),
      gender: displayValidationRequired(values.gender, 'Gender'),
      description: displayValidationRequired(values.description, 'Description'),
      employmentType: displayValidationRequired(
        values.employmentType,
        'Employment type'
      ),
      dateOfBirth: displayValidationRequired(
        values.dateOfBirth,
        'Date Of Birth'
      ),
      idNumber: displayValidationRequired(values.idNumber, 'Id number'),
      occupation: displayValidationRequired(values.occupation, 'Occupation'),
      premium: values.premium <= 0 ? 'Premium is required' : undefined,
      adviserName: displayValidationRequired(
        values.adviserName,
        'Adviser name'
      ),
      benefits: !(
        values.benefitCodeLifeIncome ||
        values.benefitCodeTermLifeCover ||
        values.benefitCodeWholeLifeCover ||
        values.benefitCodeBop
      )
        ? 'Please select at least one benefit'
        : undefined,
    };

    if (
      values.occupation === 'Business Owner' &&
      values.employmentType !== 'selfemployed' &&
      errors.employmentType === undefined
    )
      errors.employmentType =
        'If business owner is selected employment type must be self employed';

    return removeUndefined(errors);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      firstName: '',
      surname: '',
      gender: 'Male',
      description: '',
      employmentType: '',
      benefitCodeBop: false,
      benefitCodeLifeIncome: false,
      benefitCodeTermLifeCover: false,
      benefitCodeWholeLifeCover: false,
      occupation: '',
      premium: 0,
      adviserName: '',
      dateOfBirth: '1983-06-03',
      idNumber: '8306039372084',
      isSmoker: false,
      showManualDutiesQuestionsOnApp: true,
    },
    validate: validateForm,
    enableReinitialize: true,
    onSubmit: async values => {
      setLoading(true);
      const referenceNumber = Date.now().toString();
      await createQuote(
        referenceNumber,
        values.title,
        values.firstName,
        values.surname,
        values.gender,
        values.description,
        values.employmentType,
        values.benefitCodeBop,
        values.benefitCodeLifeIncome,
        values.benefitCodeTermLifeCover,
        values.benefitCodeWholeLifeCover,
        values.occupation,
        values.premium,
        values.adviserName,
        values.dateOfBirth,
        values.isSmoker,
        values.showManualDutiesQuestionsOnApp
      );
      if (typeof window !== 'undefined') {
        // router.push(`/app/${referenceNumber}/application-details`);
        window.location.href = `${publicRuntimeConfig.host}/app/${referenceNumber}/application-details`;
      } else {
        console.info('WINDOW WAS UNDEFINED, UNABLE TO NAVIGATE');
      }
    },
  });

  useEffect(() => {
    if (publicRuntimeConfig.useDemoScreen) {
      if (formik.isSubmitting && !formik.isValidating) {
        const errorElement = document.querySelector(
          '[data-has-form-field-error="true"] label'
        );
        if (errorElement) {
          console.log(errorElement);
          (errorElement as any).setAttribute('tabindex', '-1'); // Adding tabindex for elements not focusable
          (errorElement as any).focus();
        }
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors]);

  return (
    <>
      <Head>
        {publicRuntimeConfig.useDemoScreen && (
          <title>Let&apos;s generate dummy quote data to start testing</title>
        )}
        {!publicRuntimeConfig.useDemoScreen && (
          <title>Apply | Bidvest Life</title>
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <GlobalStyle />

      <PageContainer>
        <Header />
        <StyledLayout>
          {publicRuntimeConfig.useDemoScreen && (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <FormSection heading="Let’s generate dummy quote data to start testing">
                <Stack>
                  <Text>
                    To be able to give you the most authentic experience of the
                    new Digital Client Application without an official quote,
                    dummy quote data will be generated for your testing. To do
                    this, please complete the form below. Based on the
                    information you’ve provided certain fields within the
                    Digital Client Application will be pre-populated.
                  </Text>
                  <Text>
                    Due to the complexity of all the possible combinations of
                    user journeys, we pre-selected the following to simplify the
                    testing process and allow for a smoother user journey
                    through the Digital Client Application.
                  </Text>

                  <List
                    items={[
                      { key: 1, text: 'Date of Birth' },
                      { key: 2, text: 'ID Number' },
                      { key: 3, text: 'Insurance Type - Individual' },
                      {
                        key: 4,
                        text:
                          'Life Income - Beneficiary names, amount and term',
                      },
                      { key: 5, text: 'Life Lump Sum - Amount and term' },
                    ]}
                  />

                  <br />

                  <Text appearance="bold">
                    Please keep the below information on hand.
                  </Text>

                  <br />

                  <Select
                    name="title"
                    id="title"
                    options={[
                      { label: 'Mr', value: 'Mr' },
                      { label: 'Mrs', value: 'Mrs' },
                      { label: 'Ms', value: 'Ms' },
                      { label: 'Miss', value: 'Miss' },
                      { label: 'Dr', value: 'Dr' },
                      { label: 'Prof', value: 'Prof' },
                      { label: 'Sir', value: 'Sir' },
                      { label: 'Honorable', value: 'Honorable' },
                      { label: 'Judge', value: 'Judge' },
                      { label: 'Rev', value: 'Rev' },
                      { label: 'Rabbi', value: 'Rabbi' },
                      { label: 'Pastor', value: 'Pastor' },
                      { label: 'Brother', value: 'Brother' },
                      { label: 'Sister', value: 'Sister' },
                      { label: 'Father', value: 'Father' },
                      { label: 'Ds', value: 'Ds' },
                      { label: 'Captain', value: 'Captain' },
                      { label: 'Adv', value: 'Adv' },
                    ]}
                    placeholder="Select a title"
                    label="Title"
                    isSearchable
                    isClearable={false}
                    onChange={({ value }) =>
                      formik.setFieldValue('title', value.value)
                    }
                    error={!!(formik.touched.title && formik.errors.title)}
                    errorMessage={formik.errors.title}
                  />

                  <TextInput
                    name="firstName"
                    id="firstName"
                    label="Life insured's first name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      !!(formik.touched.firstName && formik.errors.firstName)
                    }
                    errorMessage={formik.errors.firstName}
                  />

                  <TextInput
                    name="surname"
                    id="surname"
                    label="Life insured's surname"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.surname && formik.errors.surname)}
                    errorMessage={formik.errors.surname}
                  />

                  <TextInput
                    name="adviserName"
                    id="adviserName"
                    label="Adviser Name"
                    value={formik.values.adviserName}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.adviserName && formik.errors.adviserName
                      )
                    }
                    errorMessage={formik.errors.adviserName}
                  />

                  <TextInput
                    name="premium"
                    id="premium"
                    fieldType="number"
                    prefix="R"
                    label="Premium"
                    value={formik.values.premium}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.premium && formik.errors.premium)}
                    errorMessage={formik.errors.premium}
                  />

                  <TextInput
                    name="description"
                    id="description"
                    label="Quote description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                    errorMessage={formik.errors.description}
                  />

                  <SegmentedControl
                    defaultOption="Male"
                    segments={['Male', 'Female']}
                    label="Select a gender"
                    name="gender"
                    id="gender"
                    value={formik.values.gender}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === 'Male') {
                        formik.setFieldValue('idNumber', '8306039372084');
                        formik.setFieldValue('dateOfBirth', '1983-06-03');
                      } else {
                        formik.setFieldValue('idNumber', '9011174242089');
                        formik.setFieldValue('dateOfBirth', '1990-11-17');
                      }
                      formik.handleChange(event);
                    }}
                    error={!!(formik.touched.gender && formik.errors.gender)}
                  />

                  <TextInput
                    name="dateOfBirth"
                    id="dateOfBirth"
                    label="Date of birth (Copy the date of birth to use later)"
                    placeholder="Date of birth"
                    value={formik.values.dateOfBirth}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.dateOfBirth && formik.errors.dateOfBirth
                      )
                    }
                    errorMessage={formik.errors.dateOfBirth}
                    disabled
                  />

                  <TextInput
                    name="idNumber"
                    id="idNumber"
                    label="Id Number (Copy the ID Number to use later)"
                    value={formik.values.idNumber}
                    onChange={formik.handleChange}
                    error={
                      !!(formik.touched.idNumber && formik.errors.idNumber)
                    }
                    errorMessage={formik.errors.idNumber}
                    disabled
                  />

                  <Select
                    name="occupation"
                    id="occupation"
                    options={OccupationOptions || []}
                    placeholder="Select an occupation"
                    label="Occupation"
                    isSearchable
                    isClearable={false}
                    onChange={({ value }) => {
                      formik.setFieldValue('occupation', value.label);
                    }}
                    error={
                      !!(formik.touched.occupation && formik.errors.occupation)
                    }
                    errorMessage={formik.errors.occupation}
                  />

                  <Select
                    name="employmentType"
                    id="employmentType"
                    options={[
                      { value: 'salaried', label: 'Salaried' },
                      { value: 'selfemployed', label: 'Self-Employed' },
                      { value: 'commission', label: 'Commission Earner' },
                      { value: 'contract', label: 'Contract Worker' },
                      { value: 'homemaker', label: 'Home Maker' },
                      { value: 'student', label: 'Student' },
                      { value: 'secondary', label: 'Secondary Occupation' },
                    ]}
                    placeholder="Select an employment type"
                    label="Employment type"
                    isSearchable
                    isClearable={false}
                    onChange={({ value }) =>
                      formik.setFieldValue('employmentType', value.value)
                    }
                    error={
                      !!(
                        formik.touched.employmentType &&
                        formik.errors.employmentType
                      )
                    }
                    errorMessage={formik.errors.employmentType}
                  />

                  <br />

                  <SegmentedControl
                    defaultOption="No"
                    segments={['Yes', 'No']}
                    label="Do you smoke?"
                    name="isSmoker"
                    id="isSmoker"
                    value={formik.values.isSmoker ? 'Yes' : 'No'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === 'Yes') {
                        formik.setFieldValue('isSmoker', true);
                      } else {
                        formik.setFieldValue('isSmoker', false);
                      }
                    }}
                    error={
                      !!(formik.touched.isSmoker && formik.errors.isSmoker)
                    }
                  />

                  <br />

                  <SegmentedControl
                    defaultOption="Yes"
                    segments={['Yes', 'No']}
                    label="Show Manual-Duties Questions?"
                    name="showManualDutiesQuestionsOnApp"
                    id="showManualDutiesQuestionsOnApp"
                    value={
                      formik.values.showManualDutiesQuestionsOnApp
                        ? 'Yes'
                        : 'No'
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === 'Yes') {
                        formik.setFieldValue(
                          'showManualDutiesQuestionsOnApp',
                          true
                        );
                      } else {
                        formik.setFieldValue(
                          'showManualDutiesQuestionsOnApp',
                          false
                        );
                      }
                    }}
                    error={
                      !!(
                        formik.touched.showManualDutiesQuestionsOnApp &&
                        formik.errors.showManualDutiesQuestionsOnApp
                      )
                    }
                  />

                  <br />

                  <Heading level={4}>
                    Please select the sections you would like to see in the
                    Digital Client Application.
                  </Heading>
                  <Text appearance="small">
                    These sections are dynamic and will only appear in the
                    Digital Client Application if the benefit was selected in
                    the quote.
                  </Text>

                  <Checkbox
                    id="benefitCodeLifeIncome"
                    name="benefitCodeLifeIncome"
                    label="Life Income Benefits"
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.benefitCodeLifeIncome &&
                        formik.errors.benefits
                      )
                    }>
                    <div>Business Cover Amount: 20 000 - 6 months</div>
                    <div>Child Cover Amount: 12 000 - 21st birthday</div>
                    <div>Adult Cover Amount: 15 000 - retirement age 70</div>
                  </Checkbox>

                  <Checkbox
                    id="benefitCodeTermLifeCover"
                    name="benefitCodeTermLifeCover"
                    label="Life Lump Sum - Term Life Cover"
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.benefitCodeTermLifeCover &&
                        formik.errors.benefits
                      )
                    }>
                    <div>Cover Amount: 400 000</div>
                  </Checkbox>

                  <Checkbox
                    id="benefitCodeWholeLifeCover"
                    name="benefitCodeWholeLifeCover"
                    label="Life Lump Sum - Whole of Life"
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.benefitCodeWholeLifeCover &&
                        formik.errors.benefits
                      )
                    }>
                    <div>Cover Amount: 500 000</div>
                  </Checkbox>

                  <Checkbox
                    id="benefitCodeBop"
                    name="benefitCodeBop"
                    label="Business Overhead Protector"
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.benefitCodeBop && formik.errors.benefits
                      )
                    }>
                    <div>Cover Amount: 150 000</div>
                  </Checkbox>
                  {formik.errors.benefits !== undefined &&
                    (formik.touched.benefitCodeLifeIncome ||
                      formik.touched.benefitCodeTermLifeCover ||
                      formik.touched.benefitCodeWholeLifeCover ||
                      formik.touched.benefitCodeBop) && (
                      <Text appearance="tiny" color="red">
                        {formik.errors.benefits}
                      </Text>
                    )}

                  <br />
                  <br />
                  <Center>
                    <PrimaryButton
                      loading={loading}
                      type="submit"
                      name="startApplication">
                      Start Demo
                    </PrimaryButton>
                  </Center>
                </Stack>
              </FormSection>
            </form>
          )}
          {!publicRuntimeConfig.useDemoScreen && (
            <FormSection heading="Page Not Found">
              <Stack>
                <Text>
                  It looks like you have landed up on the wrong place, please
                  contact customer service if you are stuck.
                </Text>
              </Stack>
            </FormSection>
          )}
        </StyledLayout>
      </PageContainer>
    </>
  );
};

export default Index;

const PageContainer = styled.div`
  padding-top: 80px;

  label:focus {
    outline: none;
  }

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-top: 99px;
  }
`;

const StyledLayout = styled.main`
  margin-bottom: 30px;
`;
